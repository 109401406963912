/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import * as Yup from 'yup'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { useFormik } from 'formik'
import { Alert, FormControl, InputLabel, MenuItem, Select, Snackbar, TextField } from '@mui/material'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { useCookies } from 'react-cookie'
import { isExpired, useJwt } from 'react-jwt'
import { Navigate, useParams } from 'react-router-dom'
import Loading from './Loading'
import NavbarAdmin from '../components/navbar/NavbarAdmin'
const ElectionSchema = Yup.object().shape({
    position: Yup.string()
    .min(4, 'Too Short')
    .max(50, 'Too Long')
    .required('Name is required')
})
const CandidateSchema = Yup.object().shape({
    first_name: Yup.string()
    .min(2, 'Too Short')
    .max(50, 'Too Long')
    .required('Name is required'),
    last_name: Yup.string()
    .min(2, 'Too Short')
    .max(50, 'Too Long')
    .required('Name is required')
})
const user = {
  name: 'Tom Cook',
  email: 'tom@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}
/* This example requires Tailwind CSS v2.0+ */
const people = [
    { name: 'Jane Cooper', title: 'Regional Paradigm Technician', role: 'Admin', email: 'jane.cooper@example.com' },
    { name: 'Cody Fisher', title: 'Product Directives Officer', role: 'Owner', email: 'cody.fisher@example.com' },
    // More people...
    ]
const navigation = [
  { name: 'Dashboard', href: '#', current: true },
  { name: 'Team', href: '#', current: false },
  { name: 'Projects', href: '#', current: false },
  { name: 'Calendar', href: '#', current: false },
  { name: 'Reports', href: '#', current: false },
]
const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


 
const queryClient = new QueryClient()
 
export default function AdminElection() {
return (
    <QueryClientProvider client={queryClient}>
    <App />
    </QueryClientProvider>
)
}

function App() {
    const [success, setSuccess] = useState(false);
    const [derror, setError] = useState(false)
    const [cookies] = useCookies()
    const istokenExpired = isExpired(cookies['att'])
    const {id} = useParams() 
    let values = {
        "C": id
    }

    const { isLoading, error, data } = useQuery('aelectionData', () => 
        fetch('https://stark-headland-19496.herokuapp.com/edibo/admin.election.details', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${cookies['att']}`},
            body: JSON.stringify(values, null, 2)
        }).then(res => res.json()))
    const formik = useFormik({
        initialValues: {
            position: '',
            election_code: id
        },
        validationSchema: ElectionSchema,
        onSubmit: async (values) => {
            fetch('https://stark-headland-19496.herokuapp.com/edibo/add.position', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${cookies['att']}`},
                body: JSON.stringify(values, null, 2),
                credentials: 'include'
            })
            .then(response => response.json())
            .then(function (data){
                if (data.ok){
                    formik.values.position = ''
                    setSuccess(true)
                }
                else {
                    setError(true)
                }
            })
        }
    })
    const deleteItem = (code, filter) => {
        let values = {
            "code": code,
            "filter": filter
        }
        fetch('https://stark-headland-19496.herokuapp.com/edibo/delete.election', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${cookies['att']}`},
                body: JSON.stringify(values, null, 2),
                credentials: 'include'
            })
            .then(response => response.json())
            .then(function (data){
                if (data.ok){
                    setSuccess(true)
                }
                else {
                    setError(true)
                }
            })
        
    }

    const formik1 = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            position_code: '',
            election_code: id
        },
        validationSchema: CandidateSchema,
        onSubmit: async (values) => {
            fetch('https://stark-headland-19496.herokuapp.com/edibo/add.candidate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${cookies['att']}`},
                body: JSON.stringify(values, null, 2),
                credentials: 'include'
            })
            .then(response => response.json())
            .then(function (data){
                if (data.ok){
                    formik1.values.first_name = ''
                    formik1.values.last_name = ''
                    formik1.values.position_code = ''
                    setSuccess(true)
                }
                else {
                    setError(true)
                }
            })
        }
    })
    if (error) return 'Server Error'
    if (isLoading) return <Loading />
    if (istokenExpired) return <Navigate to="/auth/asignin" />
  return (
      <>
      <Snackbar open={success}  autoHideDuration={6000} onClose={() => {setSuccess(false)}} >
            <Alert onClose={() => {setSuccess(false)}} severity="success" sx={{width: '100%'}}>
               Your entry has been Submitted
            </Alert>
        </Snackbar>
        <Snackbar open={derror} autoHideDuration={6000} onClose={() => {setError(false)}} >
            <Alert onClose={() => {setError(false)}} severity="error" sx={{width: '100%'}}>
                An error occured. Please try again.
            </Alert>
        </Snackbar>
    <div>
      <div className="bg-gray-800 pb-32">
      <NavbarAdmin first_name={data.first_name} email={data.email} />
        <header className="py-10">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold text-white">{data.data[0].name}</h1>
            <h2 className="text-xl font-bold text-white">Organiser: {data.data[0].organiser}</h2>
            <a href={"/"+data.data[0].slug1} className="text-gold-600 font-medium text-lg">Voters</a>
          </div>
        </header>
      </div>

      <main className="-mt-32">
        
        <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
          {/* Replace with your content */}
          { /*  Start Position  */ }
            <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Add a Position</h3>
                <form onSubmit={formik.handleSubmit}>
                <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                <div className="flex-1 max-w-3xl text-sm space-y-2 text-gray-500">
               
                        <div>
                            <TextField
                                fullWidth
                                id="position"
                                label="Position Name"
                                name='position'
                                type="text"
                                variant="outlined"
                                color='success'
                                value={formik.values.position}
                                onChange={formik.handleChange}
                                error={formik.touched.position && Boolean(formik.errors.position)}
                                helperText={formik.touched.position && formik.errors.position}
                                />
                        </div>
                </div>
                <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                    <button
                    type="submit"
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm"
                    >
                    Add
                    </button>
                </div>
                </div>
                </form>
                
            </div>
            </div>
           
            

            
            <div className="flex flex-col">
            <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Name
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Number of candidates
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Delete
                        </th>
                        
                        </tr>
                    </thead>
                    <tbody>
                        {(data.data[0].positions.length === 0) && 
                        <tr key='0' className={0 % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">No positions available</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">0</td>
                            
                        </tr>
                        }
                        {!(data.data[0].positions.length === 0) && data.data[0].positions.map((position, positionIdx) => (
                        <tr key={position.id} className={positionIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{position.name}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{position.n_c}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                            <button onClick={() => {deleteItem(position.code, 'position')}} className="text-red-600 hover:text-red-800">
                                Delete
                            </button>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>
            { /*  End Position  */ }

            { /*  Start Candidate  */ }
            <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Add a Candidate</h3>
                <form onSubmit={formik1.handleSubmit}>
                <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                <div className="flex-1 max-w-3xl text-sm space-y-2 text-gray-500">
               
                        <div>
                            <TextField
                                fullWidth
                                id="first_name"
                                label="First Name"
                                name='first_name'
                                type="text"
                                variant="outlined"
                                color='success'
                                value={formik1.values.first_name}
                                onChange={formik1.handleChange}
                                error={formik1.touched.first_name && Boolean(formik1.errors.first_name)}
                                helperText={formik1.touched.first_name && formik1.errors.first_name}
                                />
                        </div>
                        <div>
                            <TextField
                                fullWidth
                                id="last_name"
                                label="Last Name"
                                name='last_name'
                                type="text"
                                variant="outlined"
                                color='success'
                                value={formik1.values.last_name}
                                onChange={formik1.handleChange}
                                error={formik1.touched.last_name && Boolean(formik1.errors.last_name)}
                                helperText={formik1.touched.last_name && formik1.errors.last_name}
                                />
                        </div>
                        <div>
                        <FormControl className="relative w-full">
                            <InputLabel color='success' htmlFor="position">
                                Position
                            </InputLabel>
                            <Select 
                                labelId="Position"
                                id="position_code"
                                label="Position"
                                name="position_code"
                                color="success"
                                value={formik1.values.position_code}
                                onChange={formik1.handleChange}
                                >
                                    {(data.data[0].positions.length === 0) && 
                                        <MenuItem key='0' id='0' value=''>No Position</MenuItem>
                                    }
                                    {!(data.data[0].positions.length === 0) && data.data[0].positions.map((position, index) => (
                                        <MenuItem key={position.code} id={position.id} value={position.code}>{position.name}</MenuItem>
                                    ))}
                                </Select>
                        </FormControl>
                        </div>
                </div>
                <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                    <button
                    type="submit"
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm"
                    >
                    Add
                    </button>
                </div>
                </div>
                </form>
                
            </div>
            </div>
           
            {(data.data[0].positions.length === 0) && 
            <h2 className='font-medium text-xl mt-2'>No Candidates</h2>}
            
            {!(data.data[0].positions.length === 0) &&
            data.data[0].positions.map((position, index) => (
            <>
            <h2 className='font-bold text-lg mt-2'>{position.name}</h2>
            <div className="flex flex-col">
            <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Last Name
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            First Name
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Votes
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Delete
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                    {(position.candidates.length === 0) &&
                        <tr key='0' className={0 % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">No Candidate</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">Add a candidate</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">0</td>
                            
                        </tr>}
                        {!(position.candidates.length === 0) && position.candidates.map((candidate, candidateIdx) => (
                        <tr key={candidate.id} className={candidateIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{candidate.last_name}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{candidate.first_name}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{candidate.votes}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                            <button onClick={() => {deleteItem(candidate.code, 'candidate')}} className="text-red-600 hover:text-red-800">
                                Delete
                            </button>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div> 
            </>))}
           
            
            { /*  End Candidate  */ }

          {/* /End replace */}
        </div>
      </main>
    </div>
                        
    </>
  )
}
