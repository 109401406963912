import { AiFillCalendar } from 'react-icons/ai';
import { RiGroupFill } from 'react-icons/ri';
import { HiLocationMarker } from 'react-icons/hi'
import DocumentMeta from 'react-document-meta';
import OverlappingCTA from "../../components/cta/OverlappingCTA";
import NavbarSm from "../../components/navbar/NavbarSm";
import FooterSm from "../../components/footer/FooterSm";
import DemoModal from '../../components/modal/DemoModal';
const elections = [
    {
        id: 0,
        key: 'demo',
        eName: 'Demo Election',
        organiser: 'INEC',
        location: 'PFA Theatre',
        status: true,
        slug: '/demoVote'
    },
    {
        id: 1,
        key: 'demo1',
        eName: 'Another demo election',
        organiser: 'Akande Victor',
        location: 'BA 002',
        status: true,
    },
    {
        id:2,
        key: 'closed',
        eName: 'This is a closed Election',
        organiser: 'EU',
        location: 'BA 102',
        status: false
    }
]
const meta = {
    title: "Your Elections",
    description: "Hassl-Free",
    canonical: "https://vote.akande.com.ng/demo",
    meta: {
        charset: "utf-8",
        name: {
            keywords: "software, voting, e-voting"
        }
    }
};


  
export default function ElectionDemo(){
    
    return (
        <>
        <div>
            <DocumentMeta {...meta} />
        </div>

        <NavbarSm />
        
        <div className="container mx-auto h-screen space-y-6 overflow-auto">
        <div className='md:mt-6'>
        <DemoModal  />
        </div>
        
        <OverlappingCTA first_name='John' last_name="Doe" />
        
        
        <div className="max-w-7xl  mt-4 mx-auto sm:px-6 lg:px-8">
            <div className="bg-gray-50 shadow overflow-hidden sm:rounded-md">
            <ul  className="space-y-2 divide-y divide-gray-200">
                {elections.map((election) => (
                <li key={election.code}>
                    <a href={election.slug} className="block hover:bg-gray-50">
                    <div className="px-4 py-4 sm:px-6">
                        <div className="flex items-center justify-between">
                        <p className="text-sm font-medium text-green-600 truncate">{election.eName}</p>
                        <div className="ml-2 flex-shrink-0 flex">
                           {election.status && <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            Open
                            </p>}
                            {!election.status && <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                            Closed
                            </p>}
                        </div>
                        </div>
                        <div className="mt-2 sm:flex sm:justify-between">
                        <div className="sm:flex">
                            <p className="flex items-center text-sm text-gray-500">
                            <RiGroupFill className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                            {election.organiser}
                            </p>
                            <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                            <HiLocationMarker className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                           {election.location}
                            </p>
                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                            <AiFillCalendar className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                            <p>
                            Created on <time dateTime={election.created}>07-07-2022</time>
                            </p>
                        </div>
                        </div>
                    </div>
                    </a>
                </li>
                ))}
            </ul>
            </div>
        </div>
        </div>
        <FooterSm />
        </>
    )
}

