/* This example requires Tailwind CSS v2.0+ */
import { BsGlobe, BsInstagram, BsTwitter, BsGithub, BsLinkedin } from 'react-icons/bs';
const navigation = [
    {
        name: 'Linkedin',
        href: 'https://www.linkedin.com/in/victor-akande-570a35148/',
        icon: <BsLinkedin />,
    },
    
    {
      name: 'Instagram',
      href: 'https://instagram.com/akandeav',
      icon: <BsInstagram />,
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/akandeav',
      icon: <BsTwitter />,
    },
    {
      name: 'GitHub',
      href: 'https://github.com/akandeav',
      icon: <BsGithub />,
    },
    {
      name: 'Website',
      href: 'https://akande.com.ng',
      icon: <BsGlobe />,
    },
    
  ]
  
  export default function FooterSm() {
    return (
      <footer className="relative bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div className="flex justify-center space-x-6 md:order-2">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                <span className="sr-only">{item.name}</span>
                {item.icon}
              </a>
            ))}
          </div>
          <div className="mt-8 md:mt-0 md:order-1">
            <p className="text-center text-base text-gray-400">&copy; 2022. Edibo is powered by Hassl, inc. All rights reserved.</p>
          </div>
        </div>
      </footer>
    )
  }
  