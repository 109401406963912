import { Alert, AlertTitle, Button, Snackbar } from "@mui/material"
import { useState } from "react"
import { useCookies } from "react-cookie"
  export default function ListPositions(props) {
      const[candidate, setCandidate] = useState('')
      const[c_name, setName] = useState('')
      const[c_lname, setLname] = useState('')
      const[modal, setModal] = useState(false)
      const[error, setError] = useState(false)
      const[success, setSuccess] = useState(false)
      const[cookies] = useCookies(['_user'])

      const vote = () => {
        let values = {
            "election_code": props.election,
            "position_code": props.position,
            "candidate_code": candidate,
        }
        fetch('https://stark-headland-19496.herokuapp.com/edibo/vote', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${cookies['et']}`},
            credentials: 'include',
            body: JSON.stringify(values, null, 2)
        })
        .then(response => response.json())
        .then(function(data){
            if (data.detail === "Vote registered"){
                setModal(false)
                setSuccess(true)
            }else if (data.detail === "You have voted in this position"){
                setModal(false)
                setError(true)
            }
        })
        .catch(function(error){

        })

      }

      const handleClic = (c_code, c_name, c_lname) => {
          setCandidate(c_code)
          setName(c_name)
          setLname(c_lname)
          setModal(true)
      }
    return (
        <>
        {modal &&
        <Alert
            severity="warning"
            onClose={() => {setModal(false)}}
            className="mt-2"
            >
                <AlertTitle>Warning</AlertTitle>
                You are about to vote for {c_lname} {c_name}.<br />
                <Button
                    onClick={() => {vote()}}
                    color='inherit'
                    size='large'>Confirm</Button>
            </Alert>}

        <Snackbar open={success}  autoHideDuration={6000} onClose={() => {setSuccess(false)}} >
            <Alert onClose={() => {setSuccess(false)}} severity="success" sx={{width: '100%'}}>
                You have successfully voted for {c_lname} {c_name}
            </Alert>
        </Snackbar>
            
        <Snackbar open={error} autoHideDuration={6000} onClose={() => {setError(false)}} >
            <Alert onClose={() => {setError(false)}} severity="error" sx={{width: '100%'}}>
                A Server Error Occured. Please refresh and try again.
            </Alert>
        </Snackbar>
        
        <Snackbar open={error} autoHideDuration={6000} onClose={() => {setError(false)}} >
            <Alert onClose={() => {setError(false)}} severity="error" sx={{width: '100%'}}>
                You have already voted for this position. Choose another position.
            </Alert>
        </Snackbar>
            
        

      <div className="bg-white">
        <div className="mx-auto py-6 px-4 max-w-2xl sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-6">
            <div className="space-y-2 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
              <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Candidates</h2>
              <p className="text-xl text-gray-500">
                Vote for your preferred candidate
              </p>
            </div>
            
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 mb-2 gap-4 sm:grid-cols-2">
      {props.data.map((candidate) => (
        <div
          key={candidate.id}
          className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-gold-500"
        >
          <div className="flex-shrink-0">
          <span className="inline-flex items-center justify-center h-14 w-14 rounded-full bg-teal-700">
                <span className="text-xl font-medium leading-none text-white">{candidate.last_name[0]} {candidate.first_name[0]}</span>
            </span>
          </div>
          <div className="flex-1 min-w-0">
            <button type="button" onClick={() => handleClic(candidate.code, candidate.first_name, candidate.last_name)} className="focus:outline-none ">
              <span className="absolute inset-0" aria-hidden="true" />
              <p className="text-lg font-medium text-gray-900">{candidate.last_name}</p>
              <p className="text-left text-sm text-gray-500 truncate">{candidate.first_name}</p>
            </button>
          </div>
        </div>
      ))}
    </div>
      </>
    )
  }
  