export default function OverlappingCTA(props) {
    return (
      <div className="rounded-md shadow-lg mt-2 mx-3 bg-gradient-to-r from-green-500 to-green-800">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight text-green-200 md:text-7xl">
            <span className="block">Welcome back!</span>
            <span className="block text-white">{props.last_name} {props.first_name}</span>
          </h2>
          <p className="md:text-xl font-medium text-white">See your available elections</p>
        </div>
       
      </div>
    )
  }
  