import React, { Fragment, useState } from "react"
import { Popover, Transition } from '@headlessui/react'
// import { SearchIcon } from '@heroicons/react/solid'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import logo from "../../assets/img/Hassl.png"
import { useCookies } from "react-cookie"
import { isExpired } from "react-jwt"
import { Navigate } from "react-router-dom"
const navigation = [
    { name: 'Pricing', href: '/pricing' }
  ]
const lognavigation = [
    { name: 'Election', href: '/election'},
    { name: 'Pricing', href: '/pricing' }
  ]
export default function NavbarSm(){
    const [cookies, removeCookie] = useCookies(['_user'])
    const [redirect, setRedirect] = useState(false);
    const istokenExpired = isExpired(cookies['et'])

    const logout = () => {
        document.cookie = "et=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        setRedirect(true)
    }
    if (redirect) return <Navigate to="/auth/signin" />
    return(
        <Popover>
            <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <nav className="relative flex items-center justify-between sm:h-10 md:justify-center" aria-label="Global">
                <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                <div className="flex items-center justify-between w-full md:w-auto">
                    <a href="/">
                    <span className="sr-only">Hassl</span>
                    <img
                        className="h-8 w-auto sm:h-10"
                        src="/Hassl.png"
                        alt=""
                    />
                    </a>
                    <div className="-mr-2 flex items-center md:hidden">
                    <Popover.Button className="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
                        <span className="sr-only">main menu</span>
                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                    </div>
                </div>
                </div>
                <div className="hidden md:flex md:space-x-10">
                {istokenExpired && navigation.map((item) => (
                    <a key={item.name} href={item.href} className="font-medium text-gray-500 hover:text-gold-900">
                    {item.name}
                    </a>
                ))}
                {!istokenExpired && lognavigation.map((item) => (
                    <a key={item.name} href={item.href} className="font-medium text-gray-500 hover:text-gold-900">
                    {item.name}
                    </a>
                ))}
                </div>
                {istokenExpired &&
                
                <div className="hidden space-x-2 md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
                <span className="inline-flex rounded-md shadow">
                    <a
                    href="auth/asignin"
                    className="inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md text-green-600 bg-white hover:bg-green-800 hover:text-white"
                    >
                    Admin
                    </a>
                </span>
                <span className="inline-flex rounded-md shadow">
                    <a
                    href="auth/signin"
                    className="inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-800"
                    >
                    Vote
                    </a>
                </span>
                </div>
                }
                {!istokenExpired &&
                <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
                <span className="inline-flex rounded-md shadow">
                    <button
                    onClick={logout}
                    className="inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md text-green-600 bg-white hover:bg-green-800"
                    >
                    Log out
                    </button>
                </span>
                </div>
                }
            </nav>
            </div>

            <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            >
            <Popover.Panel
                focus
                className="relative top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            >
                <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="px-5 pt-4 flex items-center justify-between">
                    <div>
                    {/*<img
                        className="h-8 w-auto"
                        src="/Hassl.png"
                        alt=""
                        />*/}
                    </div>
                    <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gold-400 hover:text-gold-500 hover:bg-old-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                    </div>
                </div>
                <div className="px-2 pt-2 pb-3">
                    {istokenExpired && navigation.map((item) => (
                    <a
                        key={item.name}
                        href={item.href}
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gold-900 hover:bg-gray-50"
                    >
                        {item.name}
                    </a>
                    ))}
                    {!istokenExpired && lognavigation.map((item) => (
                        <div>
                    <a key={item.name} href={item.href} className="font-medium text-gray-800 hover:text-gold-900">
                    {item.name}
                    </a></div>
                ))}

                </div>
                {istokenExpired &&
                <>
                <a
                    href="/auth/asignin"
                    className="block w-full px-5 py-3 text-center font-medium text-green-600 bg-white hover:bg-green-800"
                >
                    Admin
                </a>
                <a
                    href="/auth/signin"
                    className="block w-full px-5 py-3 text-center font-medium text-green-600 bg-gray-50 hover:bg-green-800"
                >
                    Vote
                </a>
                </>}
                {!istokenExpired &&
                <button
                    onClick={logout}
                    className="block w-full px-5 py-3 text-center font-medium text-green-600 bg-gray-50 hover:bg-green-800"
                >
                    Log out
                </button>}
                </div>
            </Popover.Panel>
            </Transition>
        </Popover>
    )

}