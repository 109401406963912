
import { Routes, Route } from 'react-router-dom'
import Signin from '../views/Signin'
import Footer from '../components/footer/Footer'
import MissingPage from './MissingPage'
import AdminSignin from '../views/AdminSignin'

export default function Auth() {
  return (
    <>
    <div 
      className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
      style={{
        backgroundImage:"url(/bg-1.png)",
        backgroundSize: "cover",
        backgroundRepeat:"no-repeat"
      }}>     
      <Routes>
        {/*<Route path="signup" element={<Signup/>} />*/}
        <Route path="signin" element={<Signin/>} />
        <Route path="aSignin" element={<AdminSignin/>} />
        <Route path="*" element={<MissingPage />} />
      </Routes>
      
    </div>
    <Footer />
    </>
  )
}
