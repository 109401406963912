import React, { useState } from "react";
import { LockClosedIcon } from "@heroicons/react/solid"
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup"
import { useCookies } from "react-cookie";
import SmallNotification from "../components/Notification/SmallNotification";
import { Navigate } from "react-router-dom";
import DocumentMeta from "react-document-meta"
import { isExpired, useJwt } from "react-jwt";
const SiginSchema = Yup.object().shape({
  admin_id: Yup.string()
  .min(2, "Too short")
  .max(20, "Too long")
  .required("Username is required"),
  password: Yup.string()
  .required("Password is required")
})

const meta = {
  title: "Creator Login",
  description: "Hassl-Free Software",
  canonical: "https://vote.akande.com.ng/auth/asignin",
  meta: {
      charset: "utf-8",
      name: {
          keywords: "edibbo, software, voting, e-voting"
      }
  }
};

const AdminSignin = () => {
  const [error, setError] = useState('');
  const [showerror, setShowError] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [token, setToken] = useState('')
  const [cookies, setCookie] = useCookies(['_user'])
  const istokenExpired = isExpired(cookies['att'])
  //const cook = new Cookies();
  const formik = useFormik({
    initialValues: {
      admin_id: '',
      password: '',
    },
    validationSchema: SiginSchema,
    onSubmit: async (values) => {
      fetch('https://stark-headland-19496.herokuapp.com/edibo/admin.login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
      },
        body: JSON.stringify(values, null, 2)
      })
      .then(response => response.json())
      .then(function (data){
        if (data.ok){
          if (data.ok){
            setCookie('att', data.token, {'path': '/', maxAge: 3600});
            setRedirect(true)
          }
        }else if (!data.ok){
          setError(data.detail)
          setShowError(true)
        }
      })
      .catch(function (error){
        setError("Server Error")
        setShowError(true)
      })
    }
  })
  if (redirect) {
    return <Navigate to="/admin" />
  }
  if (!istokenExpired){
    return <Navigate to="/admin" />
  }
  return(
      <>
      <div>
        <DocumentMeta {...meta} />
      </div>
      {showerror &&
      <button onClick={() => {
        setShowError(false)
      }}>
    <SmallNotification status="error" message="Error" detail={error} /> </button>}
     
      <div className="max-w-xl shadow-lg rounded-sm bg-gray-50 p-6 w-full space-y-8">
      <div>
        <div className="flex flex-col justify-center items-center">
          <a href="/">
          <img
            className="mx-auto h-12 w-auto"
            src="/Hassl.png"
            alt="Hassl"
          />
          </a>
        </div>
        
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Creator Sign in</h2>
      </div>
      <form  onSubmit={formik.handleSubmit} className="mt-8 space-y-6">
        <div className="rounded-md shadow-sm space-y-3">
          <div >
            <TextField
              fullWidth
              id="admin_id"
              label="Username"
              name="admin_id"
              type="text"
              variant="outlined"
              color="success"
              value={formik.values.admin_id}
              onChange={formik.handleChange}
              error={formik.touched.admin_id && Boolean(formik.errors.admin_id)}
              helperText={formik.touched.admin_id && formik.errors.admin_id}
              />
          </div>
          <div >
            <TextField
              fullWidth
              id="password"
              label="Password"
              name="password"
              type="password"
              variant="outlined"
              color="success"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              />
          </div>
        </div>
        <div>
          <button
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              <LockClosedIcon className="h-5 w-5 text-gray-500 group-hover:text-gray-400" aria-hidden="true" />
            </span>
            Sign in
          </button>
        </div>
      </form>
    </div>
    </>
  )
}
    
export default AdminSignin