import React from "react";
import MiniCTA from "../components/cta/MiniCTA";
import Footer from "../components/footer/Footer";
import CenteredHero from "../components/hero/CenteredHero";
import Testimonial from "../components/testimonial/Testimonial";
import DocumentMeta from "react-document-meta";

const meta = {
    title: "Home",
    description: "Hassl-Free Software",
    canonical: "https://vote.akande.com.ng",
    meta: {
        charset: "utf-8",
        name: {
            keywords: "software, voting, e-voting"
        }
    }
};

export default function Landing () {
    return(
        <>
        <div>
            <DocumentMeta {...meta} />
        </div>
        <CenteredHero />
        <Testimonial />
        <MiniCTA />
        <Footer />
        </>
    )
}