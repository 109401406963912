import { React,  useState } from "react";
import { LockClosedIcon } from "@heroicons/react/solid"
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup"
import { useCookies } from "react-cookie";
import SmallNotification from "../components/Notification/SmallNotification";
import { Navigate } from "react-router-dom";
import {  createTheme } from "@mui/system";
import DocumentMeta from "react-document-meta";
import { isExpired } from "react-jwt";

const meta = {
    title: "Voter Signin",
    description: "Hassl-Free",
    canonical: "https://phis.fedgen.net/auth/signin",
    meta: {
        charset: "utf-8",
        name: {
            keywords: "software, voting, e-voting"
        }
    }
};
const SiginSchema = Yup.object().shape({
  voter_id: Yup.string()
  .required("Voter ID is required"),
  voter_key: Yup.string()
  .required("Voter key is required")
})

const Signin = () => {
  const [error, setError] = useState('');
  const [showerror, setShowError] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [cookies, setCookie] = useCookies(['_user'])
  const istokenExpired = isExpired(cookies['et'])
 

  const formik = useFormik({
    initialValues: {
      voter_id: '',
      voter_key: '',
    },
    validationSchema: SiginSchema,
    onSubmit: async (values) => {
      fetch('https://stark-headland-19496.herokuapp.com/edibo/voter.login', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(values, null, 2)
      })
      .then(function (response){
        if (response.ok){
          response.json().then(data => {
            if (data.ok){
              if (!cookies['et']){
                setCookie('et', data.token, {'path': '/', maxAge: 3600});
                setRedirect(true)
              }
            }else if (!data.ok){
              setError(data.detail)
              setShowError(true)
            }
          })
        }
      })
      .catch(function(error){
        setError("Server Unreachable. Please try again.")
        setShowError(true)
      })
    }
  })

  if (redirect){
    return <Navigate to="/election" />
  }
  if (!istokenExpired){
    return <Navigate to="/election" />
  }
    
      return(
          <>
          <div>
            <DocumentMeta {...meta} />
          </div>
          {showerror &&
      <a onClick={() => {
        setShowError(false)
      }}>
    <SmallNotification status="error" message="Error" detail={error} /> </a>}
          <div className="max-w-xl shadow-lg rounded-sm bg-gray-50 p-6 w-full space-y-8">
          <div>
            <div className="flex flex-col justify-center items-center">
              <a href="/">
              <img
                className="mx-auto h-12 w-auto"
                src="/Hassl.png"
                alt="Hassl"
              />
              </a>
            </div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to vote</h2>
            
          </div>
          <form className="mt-8 space-y-6" onSubmit={formik.handleSubmit}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm space-y-3">
              <div >
                
                <TextField
                  fullWidth
                  id="voter_id"
                  label="Voter ID"
                  name="voter_id"
                  type="text"
                  variant="outlined"
                  color="success"
                  value={formik.values.voter_id}
                  onChange={formik.handleChange}
                  error={formik.touched.voter_id && Boolean(formik.errors.voter_id)}
                  helperText={formik.touched.voter_id && formik.errors.voter_id}
                  />
              </div>
              <div >
                <TextField
                  fullWidth
                  id="voter_key"
                  label="Voter Key"
                  name="voter_key"
                  type="text"
                  variant="outlined"
                  color="success"
                  value={formik.values.voter_key}
                  onChange={formik.handleChange}
                  error={formik.touched.voter_key && Boolean(formik.errors.voter_key)}
                  helperText={formik.touched.voter_key && formik.errors.voter_key}
                  />
              </div>
            </div>
  
            
  
            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-gray-500 group-hover:text-gray-400" aria-hidden="true" />
                </span>
                Sign in
              </button>
            </div>
          </form>
        </div>
        </>
      )
    }
    
export default Signin