import NavbarSm from "../components/navbar/NavbarSm";
import { Navigate, useParams } from "react-router-dom";
import { useState } from "react";
import Position from "../components/forms/Position";

import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { useCookies } from "react-cookie";
import { isExpired } from "react-jwt";
import Loading from "./Loading";
import DocumentMeta from "react-document-meta";
import FooterSm from "../components/footer/FooterSm";

const meta = {
    title: "Vote",
    description: "Hassl-Free",
    canonical: "https://vote.akande.com.ng/vote",
    meta: {
        charset: "utf-8",
        name: {
            keywords: "software, voting, e-voting"
        }
    }
};
 const queryClient = new QueryClient()
 
 export default function Vote() {
   return (
       <>
       <DocumentMeta {...meta} />
     <QueryClientProvider client={queryClient}>
       <App />
     </QueryClientProvider>
     </>
   )
 }
function App(){
    const [edata, setData] = useState('')
    const {id} = useParams()
    const [cookies] = useCookies()
    const istokenExpired = isExpired(cookies['et'])
    const b = {
        "ecode": id
    }
    const {isLoading, error, data} = useQuery('voteData', () => 
        fetch('https://stark-headland-19496.herokuapp.com/edibo/election.detail', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${cookies['et']}`},
            body: JSON.stringify(b, null, 2)
        }).then(res => res.json()))
        
    
    if (isLoading) {
        return <Loading />
    }
    if (istokenExpired) {
        return <Navigate to="/auth/signin" />
    }
    return(
        <>
            <NavbarSm />
            <div className="h-screen overflow-auto">
            <Position data={data.data[0].positions} election={id} />
            
            </div>
            <FooterSm />
            
        </>
    )
}
