/* This example requires Tailwind CSS v2.0+ */
import { QueryClient, QueryClientProvider, useQuery } from "react-query"
import { AiFillCalendar } from 'react-icons/ai';
import { RiGroupFill } from 'react-icons/ri';
import { HiLocationMarker } from 'react-icons/hi'
import { useCookies } from "react-cookie";
import { isExpired } from "react-jwt";
import { Navigate } from "react-router-dom";
import OverlappingCTA from "../components/cta/OverlappingCTA";
import NavbarSm from "../components/navbar/NavbarSm";
import EmptyState from "../components/cta/EmptyState";
import Loading from "./Loading";
import DocumentMeta from "react-document-meta";
import FooterSm from "../components/footer/FooterSm";

const meta = {
    title: "Your Elections",
    description: "Hassl-Free",
    canonical: "https://vote.akande.com.ng/election",
    meta: {
        charset: "utf-8",
        name: {
            keywords: "software, voting, e-voting"
        }
    }
};
const queryClient = new QueryClient()


  
function Main(){
    const [cookies] = useCookies()
    const istokenExpired = isExpired(cookies['et']) 
    const { isLoading, error, data } = useQuery('data', () => 
        fetch('https://stark-headland-19496.herokuapp.com/edibo/get.election', {
            credentials: 'include',
            headers: {
                Authorization: `${cookies['et']}`
            }})
            .then( res => res.json()))
    if (isLoading) return <Loading />
    if (error) return "An error has occured: " + error.message
    if (istokenExpired){
        return <Navigate to="/auth/signin" />
    }
    
    return (
        <>
        <NavbarSm />
        <div className="container mx-auto h-screen space-y-6 overflow-auto">
        <OverlappingCTA first_name={data.voter_f_name} last_name={data.voter_l_name} />
        {(data.data.length === 0 || !data) &&
        
        <EmptyState />
       

        }
        {data.data.length !== 0 &&
        <div className="max-w-7xl mt-4 mx-auto sm:px-6 lg:px-8">
            <div className="bg-gray-50 shadow overflow-hidden sm:rounded-md">
            <ul  className="divide-y divide-gray-200">
                {data.data.map((election, idx) => (
                <li key={idx}>
                    <a href={election.slug} className="block hover:bg-gray-50">
                    <div className="px-4 py-4 sm:px-6">
                        <div className="flex items-center justify-between">
                        <p className="text-sm font-medium text-green-600 truncate">{election.name}</p>
                        <div className="ml-2 flex-shrink-0 flex">
                           {election.status && <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            Open
                            </p>}
                            {!election.status && <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                            Closed
                            </p>}
                        </div>
                        </div>
                        <div className="mt-2 sm:flex sm:justify-between">
                        <div className="sm:flex">
                            <p className="flex items-center text-sm text-gray-500">
                            <RiGroupFill className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                            {election.organiser}
                            </p>
                            <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                            <HiLocationMarker className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                           {election.location}
                            </p>
                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                            <AiFillCalendar className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                            <p>
                            Created on <time dateTime={election.created}>{election.created}</time>
                            </p>
                        </div>
                        </div>
                    </div>
                    </a>
                </li>
                ))}
            </ul>
            </div>
        </div>
        }
        </div>
        <FooterSm />
        </>
    )
}

export default function Elections() {
    
    return (
        <>
        <DocumentMeta {...meta} />
        <QueryClientProvider client={queryClient}>
            <Main />
        </QueryClientProvider>
        </>
    )
  }