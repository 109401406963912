import { FaVoteYea } from 'react-icons/fa'

export default function EmptyState() {
  return (
    <div className="mt-3 text-center">
      <FaVoteYea className="text-green-700 mx-auto text-center text-3xl md:text-6xl" />
      <h3 className="mt-2 text-sm font-medium text-gray-900">No Elections</h3>
      <p className="mt-1 text-sm text-gray-500">You'll see available elections here!</p>
      <div className="mt-6">
        {/* Button */}
      </div>
    </div>
  )
}
