import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './input.css';
import Landing from './layouts/Landing';
import reportWebVitals from './reportWebVitals';
import Auth from './layouts/Auth';
import Signin from './views/Signin';
import MissingPage from './layouts/MissingPage';
import AdminSignin from './views/AdminSignin';
import Elections from './layouts/Elections';
import Vote from './layouts/Vote';
import Pricing from './layouts/Pricing';
import Admin from './layouts/Admin';
import AdminElection from './layouts/AdminElection';
import AddVoter from './layouts/AddVoter';
import ElectionDemo from './layouts/demo/ElectionDemo';
import DemoVote from './layouts/demo/DemoVote';

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Landing />} />
      <Route path="demo" element={<ElectionDemo />} />
      <Route path="demoVote" element={<DemoVote />} />
      <Route  path="auth/*" element={<Auth />}>
        {/*<Route path="signup" element={<Signup />} />*/}
        <Route path="aSignin" element={<AdminSignin/>} />
        <Route path="signin" element={<Signin />} />
      </Route>
      <Route  path="election" element={<Elections />}></Route>
      <Route  path="admin" element={<Admin />}></Route>
      <Route  path="admin/election/:id" element={<AdminElection />}></Route>
      <Route  path="admin/voters/:id" element={<AddVoter />}></Route>
      <Route  path="pricing" element={<Pricing />}></Route>
      <Route  path="vote/:id" element={<Vote />}></Route>
      <Route path="*" element={<MissingPage />} />
    </Routes>
  </BrowserRouter>,
  rootElement
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
