/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import * as Yup from 'yup'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { useFormik } from 'formik'
import { Alert, AlertTitle, Button, Snackbar, TextField } from '@mui/material'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { useCookies } from 'react-cookie'
import { isExpired } from 'react-jwt'
import { Navigate } from 'react-router-dom'
import Loading from './Loading'
import NavbarAdmin from '../components/navbar/NavbarAdmin'
const ElectionSchema = Yup.object().shape({
    ename: Yup.string()
    .min(2, 'Too Short')
    .max(50, 'Too Long')
    .required('Name is required'),
    organisers: Yup.string()
    .min(2, 'Too Short')
    .max(50, 'Too Long')
    .required('Organisation is required'),
    location: Yup.string()
    .min(2, 'Too Short')
    .max(50, 'Too Long')
    .required('Location is required')
})


 
const queryClient = new QueryClient()
 
export default function Admin() {
return (
    <QueryClientProvider client={queryClient}>
    <App />
    </QueryClientProvider>
)
}

function App() {
    const [mcode, setCode] = useState('');
    const [mfilter, setFilter] = useState('');
    const [success, setSuccess] = useState(false);
    const [derror, setError] = useState(false);
    const [warning, setWarning] = useState(false);
    const [cookies] = useCookies()
    const istokenExpired = isExpired(cookies['att']) 
    
    const { isLoading, error, data } = useQuery('adminData', () => 
        fetch('https://stark-headland-19496.herokuapp.com/edibo/admin.election', {
            credentials: 'include',
            headers: {
                Authorization: `${cookies['att']}`
            }
        }).then(res => res.json()))
    
    const formik = useFormik({
        initialValues: {
            ename: '',
            organisers: '',
            location: ''
        },
        validationSchema: ElectionSchema,
        onSubmit: async (values) => {
            fetch('https://stark-headland-19496.herokuapp.com/edibo/add.election', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${cookies['att']}`},
                body: JSON.stringify(values, null, 2),
                credentials: 'same-origin'
                
            })
            .then(response => response.json())
            .then(function (data){
                if (data.ok){
                    formik.values.ename = ''
                    formik.values.location = ''
                    formik.values.organisers = ''
                    setSuccess(true)
                }
                else {
                    setError(true)
                }
            })
        }
    })

    const deleteItem = () => {
        let values = {
            "code": mcode,
            "filter": "election"
        }
        fetch('https://stark-headland-19496.herokuapp.com/edibo/delete.election', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${cookies['att']}`},
                body: JSON.stringify(values, null, 2),
                credentials: 'include',
                
            })
            .then(response => response.json())
            .then(function (data){
                if (data.ok){
                    setSuccess(true)
                }
                else {
                    setError(true)
                }
            })
        
    }
    const handleclic = (election_code, election) => {
        setCode(election_code)
        setFilter(election)
        setWarning(true)
    }
    if (isLoading) return <Loading />
    if (error) return 'Server Error'
    if (istokenExpired) return <Navigate to="/auth/asignin" />
  return (
      <>
      
      <Snackbar open={success}  autoHideDuration={6000} onClose={() => {setWarning(false); setSuccess(false)}} >
            <Alert onClose={() => {setSuccess(false)}} severity="success" sx={{width: '100%'}}>
                You have created a new election
            </Alert>
        </Snackbar>
        <Snackbar open={derror} autoHideDuration={6000} onClose={() => {setError(false)}} >
            <Alert onClose={() => {setError(false)}} severity="error" sx={{width: '100%'}}>
                An error occured. Please try again.
            </Alert>
        </Snackbar>
    <div>
      <div className="bg-gray-800 pb-32">
        <NavbarAdmin first_name={data.first_name} email={data.email} />
        <header className="py-10">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold text-white">Dashboard</h1>
          </div>
        </header>
      </div>

      <main className="-mt-32">
        
        <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
          {/* Replace with your content */}
         
            <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Create Election</h3>
                <form onSubmit={formik.handleSubmit}>
                <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                <div className="flex-1 max-w-3xl text-sm space-y-2 text-gray-500">
               
                        <div>
                            <TextField
                                fullWidth
                                id="ename"
                                label="Election Name"
                                name='ename'
                                type="text"
                                variant="outlined"
                                color='success'
                                value={formik.values.ename}
                                onChange={formik.handleChange}
                                error={formik.touched.ename && Boolean(formik.errors.ename)}
                                helperText={formik.touched.ename && formik.errors.ename}
                                />
                        </div>
                        <div>
                            <TextField
                                fullWidth
                                id="organisers"
                                label="Organiser"
                                name="organisers"
                                type="text"
                                variant="outlined"
                                color='success'
                                value={formik.values.organisers}
                                onChange={formik.handleChange}
                                error={formik.touched.organisers && Boolean(formik.errors.organisers)}
                                helperText={formik.touched.organisers && formik.errors.organisers}
                                />
                        </div>
                        <div>
                            <TextField
                                fullWidth
                                id="location"
                                label="Location"
                                name='location'
                                type="text"
                                variant="outlined"
                                color='success'
                                value={formik.values.location}
                                onChange={formik.handleChange}
                                error={formik.touched.location && Boolean(formik.errors.location)}
                                helperText={formik.touched.location&& formik.errors.location}
                                />
                        </div>
                        
                    
                </div>
                <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                    <button
                    type="submit"
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm"
                    >
                    Create
                    </button>
                </div>
                </div>
                </form>
                
            </div>
            </div>
           
            {warning &&
            <Alert
                severity="warning"
                onClose={() => {setWarning(false)}}
                className="mt-2"
                >
                    <AlertTitle>Warning</AlertTitle>
                    You are about to Delete {mfilter}.<br />
                    <Button
                        onClick={() => {deleteItem()}}
                        color='inherit'
                        size='small'>Confirm</Button>
                </Alert>
            }

            <h2 className='font-bold text-lg mt-2'>Elections</h2>
            <div className="flex flex-col">
            <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Name
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Organisation
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Location
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Created
                        </th>
                        <th scope="col" className="relative px-6 py-3">
                            <span className="sr-only">Edit</span>
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.data.map((election, electionIdx) => (
                        <tr key={election.code} className={electionIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{election.name}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{election.organiser}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{election.location}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{election.created}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <a href={election.slug} className="text-green-800 hover:text-green-900">
                                Details
                            </a>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <a href={election.slug1} className="text-gold-800 hover:text-gold-900">
                                Voters
                            </a>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <button onClick={() => {handleclic(election.code, election.name)}} className="text-red-700 hover:text-red-800">
                                Delete
                            </button>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>
            

          {/* /End replace */}
        </div>
      </main>
    </div>
    </>
  )
}
