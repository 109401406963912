import { BsGithub, BsGlobe, BsInstagram, BsLinkedin, BsTwitter } from "react-icons/bs"

const navigation = {
  main: [
    { name: 'About', href: '#' },
    { name: 'Pricing', href: '/pricing' },
    { name: 'Products', href: '/election' },
    { name: 'Careers', href: '#' },
    { name: 'Contact', href: '#' },
    { name: 'Partners', href: '#' },
  ],
  social: [
    
    {
      name: 'Linkedin',
      href: 'https://www.linkedin.com/in/victor-akande-570a35148/',
      icon: <BsLinkedin />,
  },
  
  {
    name: 'Instagram',
    href: 'https://instagram.com/akandeav',
    icon: <BsInstagram />,
  },
  {
    name: 'Twitter',
    href: 'https://twitter.com/akandeav',
    icon: <BsTwitter />,
  },
  {
    name: 'GitHub',
    href: 'https://github.com/akandeav',
    icon: <BsGithub />,
  },
  {
    name: 'Website',
    href: 'https://akande.ng',
    icon: <BsGlobe />,
  },
  ],
}

export default function Footer() {
  return (
    <footer className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          {navigation.main.map((item) => (
            <div key={item.name} className="px-5 py-2">
              <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <div className="mt-8 flex justify-center space-x-6">
          {navigation.social.map((item) => (
            <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">{item.name}</span>
              {item.icon}
            </a>
          ))}
        </div>
        <p className="mt-8 text-center text-base text-gray-400">&copy; 2022 Hassl, Inc. All rights reserved.</p>
      </div>
    </footer>
  )
}
