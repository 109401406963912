import { Fragment, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Alert, FormControl, InputLabel, MenuItem, Select, Snackbar, TextField } from '@mui/material'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { useCookies } from 'react-cookie'
import { isExpired, useJwt } from 'react-jwt'
import { Navigate, useParams } from 'react-router-dom'
import generatePDF from '../components/forms/generatePDF'
import NavbarAdmin from '../components/navbar/NavbarAdmin'

const CandidateSchema = Yup.object().shape({
    first_name: Yup.string()
    .min(4, 'Too Short')
    .max(50, 'Too Long')
    .required('Name is required'),
    last_name: Yup.string()
    .min(4, 'Too Short')
    .max(50, 'Too Long')
    .required('Name is required')
})


 
const queryClient = new QueryClient()
 
export default function AddVoter() {
return (
    <QueryClientProvider client={queryClient}>
    <App />
    </QueryClientProvider>
)
}

function App() {
    const [mess, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [derror, setError] = useState(false)
    const [cookies] = useCookies()
    const istokenExpired = isExpired(cookies['att'])
    const {id} = useParams() 
    let values = {
        "C": id
    }

    const { isLoading, error, data } = useQuery('voterData', () => 
        fetch('https://stark-headland-19496.herokuapp.com/edibo/election.voters', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${cookies['att']}`},
            body: JSON.stringify(values, null, 2)
        }).then(res => res.json()))

    const deleteItem = (code, filter) => {
        let values = {
            "code": code,
            "filter": filter
        }
        fetch('https://stark-headland-19496.herokuapp.com/edibo/delete.election', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${cookies['att']}`},
                body: JSON.stringify(values, null, 2),
                credentials: 'include'
            })
            .then(response => response.json())
            .then(function (data){
                if (data.ok){
                    setSuccess(true)
                }
                else {
                    setError(true)
                }
            })
        
    }

    const formik1 = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            voter_id: '',
            election_code: id
        },
        validationSchema: CandidateSchema,
        onSubmit: async (values) => {
            fetch('https://stark-headland-19496.herokuapp.com/edibo/add.voter', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${cookies['att']}`},
                body: JSON.stringify(values, null, 2),
                credentials: 'include'
            })
            .then(response => response.json())
            .then(function (data){
                if (data.ok){
                    formik1.values.first_name = ''
                    formik1.values.last_name = ''
                    formik1.values.voter_id = ''
                    setSuccess(true)
                }
                else {
                    setMessage(data.detail)
                    setError(true)
                }
            })
        }
    })
    if (error) return 'Server Error'
    if (isLoading) return 'loading...'
    if (istokenExpired) return <Navigate to="/auth/asignin" />
  return (
      <>
      <Snackbar open={success}  autoHideDuration={6000} onClose={() => {setSuccess(false)}} >
            <Alert onClose={() => {setSuccess(false)}} severity="success" sx={{width: '100%'}}>
               Your entry has been Submitted
            </Alert>
        </Snackbar>
        <Snackbar open={derror} autoHideDuration={6000} onClose={() => {setError(false)}} >
            <Alert onClose={() => {setError(false)}} severity="error" sx={{width: '100%'}}>
                An error occured. {mess}. Please try again.
            </Alert>
        </Snackbar>
    <div>
      <div className="bg-gray-800 pb-32">
      <NavbarAdmin first_name={data.first_name} email={data.email} />
        <header className="py-10">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold text-white">{data.election_name} Voters</h1>
            <h2 className="text-xl font-bold text-white">Organiser: {data.organiser}</h2>
            <a href={"/admin/election/"+data.code} className="text-gold-600 font-medium text-lg">Details</a>
          </div>
        </header>
      </div>

      <main className="-mt-32">
        
        <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
          {/* Replace with your content */}
            { /*  Start Candidate  */ }
            <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Add a Voter</h3>
                <form onSubmit={formik1.handleSubmit}>
                <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                <div className="flex-1 max-w-3xl text-sm space-y-2 text-gray-500">
               
                        <div>
                            <TextField
                                fullWidth
                                id="first_name"
                                label="First Name"
                                name='first_name'
                                type="text"
                                variant="outlined"
                                color='success'
                                value={formik1.values.first_name}
                                onChange={formik1.handleChange}
                                error={formik1.touched.first_name && Boolean(formik1.errors.first_name)}
                                helperText={formik1.touched.first_name && formik1.errors.first_name}
                                />
                        </div>
                        <div>
                            <TextField
                                fullWidth
                                id="last_name"
                                label="Last Name"
                                name='last_name'
                                type="text"
                                variant="outlined"
                                color='success'
                                value={formik1.values.last_name}
                                onChange={formik1.handleChange}
                                error={formik1.touched.last_name && Boolean(formik1.errors.last_name)}
                                helperText={formik1.touched.last_name && formik1.errors.last_name}
                                />
                        </div>
                        <div>
                            <TextField
                                fullWidth
                                id="voter_id"
                                label="Voter ID"
                                name='voter_id'
                                type="text"
                                variant="outlined"
                                color='success'
                                value={formik1.values.voter_id}
                                onChange={formik1.handleChange}
                                error={formik1.touched.voter_id && Boolean(formik1.errors.voter_id)}
                                helperText={formik1.touched.voter_id && formik1.errors.voter_id}
                                />
                        </div>
                        
                </div>
                <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                    <button
                    type="submit"
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm"
                    >
                    Add
                    </button>
                </div>
                </div>
                </form>
                
            </div>
            </div>
           
            {(data.data.length === 0) && 
            <h2 className='font-medium text-xl mt-2'>No Voter</h2>}
            
            {!(data.data.length === 0) && 
            <>
            <h2 className='font-bold text-xl mt-2'>Voters</h2>
            <button 
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm" 
                onClick={() => {generatePDF(data.data)}}
                >Print</button>
            <div className="flex flex-col">
            <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Last Name
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            First Name
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Voter ID
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Voter Key
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Delete
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                    {(data.data.length === 0) &&
                        <tr key='0' className={0 % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">No Voter</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">Add a Voter</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">0</td>
                            
                        </tr>}
                        {!(data.data.length === 0) && data.data.map((voter, voterIdx) => (
                        <tr key={voter.id} className={voterIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{voter.last_name}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{voter.first_name}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{voter.voter_id}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{voter.voter_key}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                            <button onClick={() => {deleteItem(voter.voter_key, 'voter')}} className="text-red-600 hover:text-red-800">
                                Delete
                            </button>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div> 
           </>
            }
            
            { /*  End Candidate  */ }

          {/* /End replace */}
        </div>
      </main>
    </div>
                        
    </>
  )
}
