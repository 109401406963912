import { LinearProgress } from "@mui/material";

export default function Loading(){
    return (
        <div className="relative h-screen  bg-gradient-to-br from-purple-200 to-fuchsia-400">
            <div className="">
                <div className="relative">
                    <div className="container mx-auto px-4 ">
                        <div className="flex h-screen items-center">
                            <div className=" mx-auto w-full h-2/3 lg:h-5/6 shadow-xl rounded-lg bg-zinc-800">
                                
                                <div className="mt-12 my-auto  mx-auto w-full h-5/6">
                                    <div className="flex flex-row justify-center items-center h-5/6">
                                        <div className="flex flex-col justify-center items-center">
                                            <div className="w-full">
                                                <LinearProgress  color="success" />
                                                <p className="font-sans font-medium text-center mt-2 text-neutral-400">Loading</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}