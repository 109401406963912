import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import ListPositions from "../hero/ListPostions";

export default function Position(props) {
    const [idx, setIdx] = useState('')
    const formik = useFormik({
        initialValues: {
            Position: ''
        },
        onSubmit: () => {
            
        }
    })
    return (
        <>
      <div className="relative bg-white py-4 sm:py-2">
        <div className="relative sm:py-16">
          <div aria-hidden="true" className="hidden sm:block">
            <div className="absolute inset-y-0 left-0 w-1/2 bg-white rounded-r-3xl" />
            <svg className="absolute top-8 left-1/2 -ml-3" width={404} height={392} fill="none" viewBox="0 0 404 392">
              <defs>
                <pattern
                  id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gold-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={392} fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)" />
            </svg>
          </div>
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="relative rounded-2xl px-6 py-10 bg-green-600 overflow-hidden shadow-xl sm:px-12 sm:py-20">
              <div aria-hidden="true" className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
                <svg
                  className="absolute inset-0 h-full w-full"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 1463 360"
                >
                  <path
                    className="text-gold-500 text-opacity-40"
                    fill="currentColor"
                    d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                  />
                  <path
                    className="text-green-700 text-opacity-40"
                    fill="currentColor"
                    d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                  />
                </svg>
              </div>
              <div className="relative">
                <div className="sm:text-center">
                  <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                   Vote for your Candidate.
                  </h2>
                  <p className="mt-6 mx-auto max-w-2xl text-lg text-gold-200">
                    Select the available positions from the drop down .
                  </p>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit} className="px-2 mt-1 sm:mx-auto sm:max-w-2xl sm:flex">
      <div className="min-w-full flex-1">
          <div  className=" w-full">
            <FormControl className="relative w-full">
                <InputLabel color="success" htmlFor="position">
                    Position
                </InputLabel>
                <Select
                    labelId="Position"
                    id="Position"
                    label="Position"
                    name="Position"
                    color="success"
                    value={formik.values.Position}
                    onChange={formik.handleChange}
                    >
                      {(props.data.length === 0) && 
                            <MenuItem key='0' id='0' value=''>No Position</MenuItem>
                        }
                        {!(props.data.length === 0) &&props.data.map((position, index) => (
                            <MenuItem key={position.id} id={position.id} value={index}>{position.name}</MenuItem>
                        ))}
                    </Select>
            </FormControl>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-3">
          {formik.values.Position === '' &&
          <p>Select a position</p>}

        {!(formik.values.Position === '') && <ListPositions data={props.data[formik.values.Position].candidates} election={props.election} position={props.data[formik.values.Position].code}/>}
      </div>
      </div>
      
    </form>
    </>
    )
  }
  